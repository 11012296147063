/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @next/next/no-img-element */
/* eslint-disable no-unused-vars */
import "@coreui/coreui/dist/css/coreui.min.css";
import "../styles/ckl.css";
import "../styles/custom.css";
import "../styles/page-ckl.css";
import React, { useState } from "react";

import parse from "html-react-parser";

import { QueryClient, QueryClientProvider, Hydrate } from "react-query";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { store, persistor } from "../redux/store";
import Head from "next/head";

function MyApp({ Component, pageProps }) {
  const scriptFB = parse(
    `${pageProps?.headerFooter?.state?.header?.fb_pixel_script}`,
    {}
  );
  const scriptGA = parse(
    `${pageProps?.headerFooter?.state?.header?.ga_script}`,
    {}
  );

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            retry: false,
          },
        },
      })
  );

  return (
    <QueryClientProvider client={queryClient}>
      <Head>
        {scriptGA && scriptGA}

        {scriptFB && scriptFB}
      </Head>

      <Hydrate state={pageProps.dehydratedState}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            {() => <Component {...pageProps} />}
          </PersistGate>
        </Provider>
      </Hydrate>
    </QueryClientProvider>
  );
}

export default MyApp;
