import { createSlice } from '@reduxjs/toolkit';

export const userReducer = createSlice({
  name: 'user',
  initialState: {
    data: {},
    permission: [],
    token: '',
  },
  reducers: {
    setUser: (state, action) => ({
      ...state,
      data: { ...action.payload.data },
      permission: action.payload.permission,
      token: action.payload.token,
    }),
    removeUser: () => ({
      data: {},
      permission: [],
      token: '',
    }),
  },
});

export const { setUser, removeUser } = userReducer.actions;

export default userReducer.reducer;
